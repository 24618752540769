define("discourse/plugins/discourse-follow/discourse/components/follow-notification-preferences", ["exports", "@ember/component", "@ember-decorators/object", "@ember/template-factory"], function (_exports, _component, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group follow-notifications">
    <label class="control-label">{{i18n "user.follow.label"}}</label>
  
    <div class="controls">
      {{preference-checkbox
        labelKey="user.follow_notifications_options.allow_people_to_follow_me"
        checked=this.user.allow_people_to_follow_me
      }}
  
      {{preference-checkbox
        labelKey="user.follow_notifications_options.notify_me_when_followed"
        checked=this.user.notify_me_when_followed
      }}
  
      {{preference-checkbox
        labelKey="user.follow_notifications_options.notify_followed_user_when_followed"
        checked=this.user.notify_followed_user_when_followed
      }}
  
      {{preference-checkbox
        labelKey="user.follow_notifications_options.notify_me_when_followed_replies"
        checked=this.user.notify_me_when_followed_replies
      }}
  
      {{preference-checkbox
        labelKey="user.follow_notifications_options.notify_me_when_followed_creates_topic"
        checked=this.user.notify_me_when_followed_creates_topic
      }}
    </div>
  </div>
  */
  {
    "id": "nMzX18W2",
    "block": "[[[10,0],[14,0,\"control-group follow-notifications\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"user.follow.label\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.allow_people_to_follow_me\",[30,0,[\"user\",\"allow_people_to_follow_me\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed\",[30,0,[\"user\",\"notify_me_when_followed\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_followed_user_when_followed\",[30,0,[\"user\",\"notify_followed_user_when_followed\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed_replies\",[30,0,[\"user\",\"notify_me_when_followed_replies\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,1],null,[[\"labelKey\",\"checked\"],[\"user.follow_notifications_options.notify_me_when_followed_creates_topic\",[30,0,[\"user\",\"notify_me_when_followed_creates_topic\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"preference-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/components/follow-notification-preferences.hbs",
    "isStrictMode": false
  });
  const preferences = ["notify_me_when_followed", "notify_followed_user_when_followed", "notify_me_when_followed_replies", "notify_me_when_followed_creates_topic", "allow_people_to_follow_me"];
  class FollowNotificationPreferences extends _component.default {
    _updatePreferences() {
      if (!this.user.custom_fields) {
        this.user.set("custom_fields", {});
      }
      preferences.forEach(p => {
        this.user.set(`custom_fields.${p}`, this.user[p]);
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "_updatePreferences", [(0, _object.observes)(...preferences.map(p => `user.${p}`))]))();
  }
  _exports.default = FollowNotificationPreferences;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FollowNotificationPreferences);
});